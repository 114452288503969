// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import { useState } from 'react';
import { getSingle } from 'src/auth/context/firebase/auth-provider';

// ----------------------------------------------------------------------

export default function NavDemoLink() {
  const { user } = useAuthContext();

  const { t } = useLocales();

  const [copyLink, setCopyLink] = useState();

  const copyDemoLink = async () => {
    const getDemoID = await getSingle('users', 'uid', user?.uid);
    console.log(getDemoID, 'GET DEMO ID');
    const demoURL = `${window.location.origin}/demo/${getDemoID[0].demoid}`;

    navigator.clipboard
      .writeText(demoURL)
      .then(() => {
        setCopyLink(true);
        // You can show a success message or perform additional actions here
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard:', error);
        // You can show an error message or handle the error in a different way here
      });
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Button variant="contained" onClick={copyDemoLink} target="_blank" rel="noopener">
          Copy Demo Link
        </Button>
        {copyLink && <p style={{ color: 'green' }}>Copied</p>}
      </Stack>
    </Stack>
  );
}
