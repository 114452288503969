import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import ReactPlayer from 'react-player';
import Iconify from 'src/components/iconify';

// components
import Image from 'src/components/image';
import { MotionViewport, varFade } from 'src/components/animate';
import { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  getSingle,
  updateSingle,
  updateTimeWatched,
} from 'src/auth/context/firebase/auth-provider';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

// ----------------------------------------------------------------------

export default function DemoVideo() {
  const theme = useTheme();
  const { boot, shutdown, hide, show, update } = useIntercom();

  const renderImg = (
    <Image
      style={{ width: '920px', height: '720px', borderRadius: '15px' }}
      src="https://res.cloudinary.com/thechurchcoproduction/image/fetch/f_auto/https://thechurchco-production.s3.amazonaws.com/uploads/2022/01/share-image-01-newlogo.jpg"
      alt="thechurchco-demo"
      overlay={alpha(theme.palette.grey[900], 0.48)}
    />
  );

  const [showVideo, setShowVideo] = useState(false);
  const [totalVideoTime, setTotalVideoTime] = useState();
  const [businessProfilePhoto, setBusinessProfilePhoto] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessContact, setBusinessContact] = useState();
  const [businessContactTitle, setBusinessContactTitle] = useState();
  const [calendarLink, setCalendarLink] = useState();
  const [leadName, setLeadName] = useState();
  const urlID = window.location.pathname;
  const lastPart = urlID.split('/').pop();

  function getDemoId(url) {
    const regex = /\/demo\/([^/]+)/;
    const match = url.match(regex);

    if (match && match.length >= 2) {
      return match[1];
    }

    return null;
  }

  const demoId = getDemoId(urlID);

  const getBusinessDetails = async () => {
    const business = await getSingle('business', 'demoid', demoId);
    if (business) {
      setBusinessContact(business[0]?.businessContact);
      setBusinessName(business[0]?.businessName);
      setCalendarLink(business[0]?.calendarLink);
      setBusinessProfilePhoto(business[0]?.url);
      setBusinessContactTitle(business[0]?.businessContactTitle);
    }
  };

  const getLeadDetails = async () => {
    const lead = await getSingle('leads', 'leadid', lastPart);
    if (lead) {
      setLeadName(lead[0].firstName);
    }
  };

  // const getDemoVdieos = async () => {
  //   const videos = await getSingle('video', 'demoid', demoId);
  //   if (videos) {
  //     setLeadName(lead[0].firstName);
  //   }
  // };

  useEffect(() => {
    boot();
    if (!businessName) getBusinessDetails();
    if (!leadName) getLeadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessName, businessContact, calendarLink, leadName]);

  const videoRef = useRef(null);

  const [playStartTime, setPlayStartTime] = useState();
  const [playEndTime, setPlayEndTime] = useState();

  const pauseMedia = async () => {
    console.log(playStartTime, 'playStartTime');
    console.log(lastPart, 'lastPart');
    await updateTimeWatched('leads', 'leadid', lastPart, {
      playduration: videoRef.current.getSecondsLoaded(),
      playstart: playStartTime,
      playend: videoRef.current.getCurrentTime(),
      date: new Date(),
    });
  };

  useEffect(() => {
    if (totalVideoTime) {
      // console.log(totalVideoTime, 'total');
      // updateSingle('leads', 'leadid', lastPart, { totalvideotime: totalVideoTime });
    }
  }, [totalVideoTime]);

  const handleStart = () => {
    setPlayStartTime(videoRef.current.getCurrentTime());
  };
  const renderVideo = (
    <ReactPlayer
      ref={videoRef}
      controls
      style={{ borderRadius: '30px' }}
      width="100%"
      height="100%"
      playing
      url="https://thechurchco-production.s3.amazonaws.com/uploads/sites/5789/2023/07/Product-Demo-Video-SaaS-Video-Infinity.mp4"
      onReady={() => {
        setTotalVideoTime(videoRef.current.getDuration());
      }}
      playIcon={
        <Fab sx={{ position: 'absolute', zIndex: 9 }}>
          <Iconify icon="solar:play-broken" width={24} />
        </Fab>
      }
      onPlay={handleStart}
      // onProgress={handleProgress}
      onPause={pauseMedia}
    />
  );

  const renderLogo = (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        zIndex: 9,
        bottom: 0,
        opacity: 0.48,
        position: 'absolute',
        py: { xs: 1.5, md: 2.5 },
      }}
    >
      {['ibm', 'lya', 'spotify', 'netflix', 'hbo', 'amazon'].map((logo) => (
        <Box
          component={m.img}
          key={logo}
          variants={varFade().in}
          alt={logo}
          src={`/assets/icons/brands/ic_brand_${logo}.svg`}
          sx={{
            m: { xs: 1.5, md: 2.5 },
            height: { xs: 20, md: 32 },
          }}
        />
      ))}
    </Stack>
  );

  return (
    <Box
      style={{ width: '90vw' }}
      sx={{
        pb: 10,
      }}
    >
      <Typography variant="h4" style={{ textAlign: 'left' }}>
        Hey {leadName}, here&apos;s your Demo
      </Typography>
      &nbsp; &nbsp;
      <Grid container spacing={2} disableEqualOverflow>
        <Grid xs={12} md={9}>
          <Box
            sx={{
              borderRadius: 2,
              display: 'flex',
              overflow: 'hidden',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {renderVideo}
          </Box>
        </Grid>
        <Grid xs={12} md={3}>
          <Card style={{ paddingBottom: '30px' }}>
            <CardHeader sx={{ mb: 2 }} title={businessName} />
            <Grid
              spacing={1}
              container
              justify="center"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item xs={12} md={4} sx={{ pl: 2 }}>
                <img
                  style={{
                    borderRadius: '80px',
                    width: '80px',
                    height: '80px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                    border: '8px solid white',
                    boxSizing: 'content-box',
                  }}
                  src={businessProfilePhoto}
                  alt="profile"
                />
              </Grid>
              <Grid style={{ textAlign: 'left' }} item xs={12} sx={{ pl: 2 }} md={8}>
                <Typography variant="h6">{businessContact}</Typography>
                <Typography variant="body1">{businessContactTitle}</Typography>
              </Grid>
              <Grid style={{ textAlign: 'center' }} item xs={12} md={12} sx={{ p: 2, mt: 4 }}>
                <Typography
                  variant="body1"
                  sx={{ p: 4, mb: 4 }}
                  style={{
                    border: '1px solid rgba(145, 158, 171, 0.2)',
                    borderRadius: '15px',
                    boxShadow:
                      '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
                  }}
                >
                  Heres your Customized Demo. Reach out if you have any questions
                </Typography>
                <Button style={{ textAlign: 'center' }} variant="contained" onClick={show}>
                  Talk to our Team
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

// ----------------------------------------------------------------------

function TextAnimate({ text, variants, sx, ...other }) {
  return (
    <Box
      component={m.div}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter, index) => (
        <m.span key={index} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}

TextAnimate.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
  variants: PropTypes.object,
};
