import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import uuidv4 from 'src/utils/uuidv4';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  deleteDoc,
  addDoc,
  where,
  query,
} from 'firebase/firestore';

// config
import { FIREBASE_API } from 'src/config-global';
//
import { AuthContext, BusinessContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

const refStorage = getStorage(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          if (user.emailVerified) {
            const userProfile = doc(DB, 'users', user.uid);

            const docSnap = await getDoc(userProfile);

            const profile = docSnap.data();

            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...user,
                  ...profile,
                  id: user.uid,
                  role: 'admin',
                },
              },
            });
          } else {
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    await sendEmailVerification(newUser.user);

    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);

    const newId = uuidv4();

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
      demoid: newId,
    });

    const businessProfile = doc(collection(DB, 'business'), newUser.user?.uid);

    await setDoc(businessProfile, {
      url: '',
      businessName: '',
      businessContact: '',
      businessEmail: '',
      demoid: newId,
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function BusinessProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (business) => {
        if (business) {
          if (business.emailVerified) {
            const businessProfile = doc(DB, 'businesses', business.uid);

            const docSnap = await getDoc(businessProfile);

            const profile = docSnap.data();

            dispatch({
              type: 'INITIAL',
              payload: {
                business: {
                  ...business,
                  ...profile,
                  id: business.uid,
                  role: 'admin',
                },
              },
            });
          } else {
            dispatch({
              type: 'INITIAL',
              payload: {
                business: null,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              business: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          business: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.business?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      business: state.business,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [status, state.business]
  );

  return <BusinessContext.Provider value={memoizedValue}>{children}</BusinessContext.Provider>;
}

BusinessProvider.propTypes = {
  children: PropTypes.node,
};

export async function updateSingle(col, whereQuery, id, updateData) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(collectionRef, where(whereQuery, '==', id));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Update each matching document
      await updateDoc(doc(collectionRef, docSnapshot.id), updateData);
    });

    console.log('Documents updated successfully');
  } catch (error) {
    console.error('Error updating documents:', error);
  }
}

export async function updateTimeWatched(col, whereQuery, id, newData) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(collectionRef, where(whereQuery, '==', id));

  try {
    const querySnapshot = await getDocs(q);

    const leadDoc = querySnapshot.docs[0];

    if (leadDoc) {
      // Merge the existing data with the new data
      const existingData = leadDoc.data();
      if (!existingData.timewatched) {
        const data = {
          0: newData,
        };

        const updatedData = { timewatched: { ...existingData.timewatched, ...data } };
        querySnapshot.forEach(async (docSnapshot) => {
          await updateDoc(doc(collectionRef, docSnapshot.id), updatedData, { merge: true });
        });
      } else {
        let nextNumber = 0;
        let foundNumber = false;
        // let document = null;

        const docAtIndex = querySnapshot.docs[nextNumber];

        while (!foundNumber && nextNumber <= 999) {
          if (docAtIndex && docAtIndex.data().timewatched[nextNumber]) {
            nextNumber += 1;
          } else {
            // leadDoc = docAtIndex;
            foundNumber = true;
          }
        }
        const data = {
          [nextNumber]: newData,
        };

        const updatedData = { timewatched: { ...existingData.timewatched, ...data } };
        querySnapshot.forEach(async (docSnapshot) => {
          await updateDoc(doc(collectionRef, docSnapshot.id), updatedData, { merge: true });
        });

        console.log('Data added to the lead successfully!');
      }
    } else {
      console.log('Lead document not found!');
    }

    // console.log('Documents updated successfully');
  } catch (error) {
    console.error('Error updating documents:', error);
  }
}

export async function updateUserDocument(col, whereQuery, id, updateData) {
  const documentRef = doc(DB, col, id);
  try {
    await updateDoc(documentRef, updateData);
    console.log('Document updated successfully');
    return 'Document updated successfully'; // Return a success message or any desired value
  } catch (error) {
    console.error('Error updating document:', error);
    throw error; // Throw the error to be caught by the caller
  }
}

export async function addDocument(col, user, data) {
  const dbRef = collection(DB, col);
  return new Promise((resolve, reject) => {
    addDoc(dbRef, data)
      .then((docRef) => {
        resolve(docRef);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export async function getMultiple(col, whereQuery, uid) {
  const q = query(collection(DB, col), where(whereQuery, '==', uid));

  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    results.push(document.data());
  });
  return results;
}

export async function getMultipleDemoID(col, whereQuery, uid) {
  const q = query(collection(DB, col), where('demoid', '==', uid));
  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach((document) => {
    results.push(document.data());
  });
  return results;
}

export async function getSingle(col, wherequery, id) {
  const snapshot = await getDocs(collection(DB, col));
  const results = [];

  snapshot.forEach((documents) => {
    if (documents.data()[wherequery] === id) {
      results.push(documents.data());
    }
  });

  return results;
}

export async function getUniqueDemoID(col, wherequery, id) {
  const snapshot = await getDocs(collection(DB, col), where(wherequery, '===', id));
  const results = [];
  snapshot.forEach((docs) => {
    if (docs.data().id === id) {
      results.push(docs.data());
    }
  });
  return results;
}

export async function getUniqueLeadId(col, wherequery, id) {
  const snapshot = await getDocs(collection(DB, col), where(wherequery, '===', id));
  const results = [];
  snapshot.forEach((docs) => {
    if (docs.data().leadid === id) {
      results.push(docs.data());
    }
  });
  return results;
}

export async function deleteSingle(col, uid, whereQuery) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where(whereQuery, '==', uid));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Delete each matching document
      await deleteDoc(doc(collectionRef, docSnapshot.id));
    });

    console.log('Documents deleted successfully');
  } catch (error) {
    console.error('Error deleting documents:', error);
  }
}

export function uploadMedia(uid, col, file, demoid) {
  console.log(col, demoid, 'DEMO ID');
  return new Promise((resolve, reject) => {
    const storageRef = ref(refStorage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // Handle progress if needed
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log(downloadURL, 'DOWNLOAD');

          const collectionRef = collection(DB, col);
          // const q = query(collectionRef, where('demoid', '==', demoid));

          if (demoid) {
            const q = query(collectionRef, where('demoid', '==', demoid));

            try {
              const querySnapshot = await getDocs(q);

              if (!querySnapshot.empty) {
                // Document exists, update it
                querySnapshot.forEach(async (docSnapshot) => {
                  // Update each matching document to remove the URL field
                  await updateDoc(doc(collectionRef, docSnapshot.id), {
                    url: downloadURL,
                  });
                });
              } else {
                const demoID = demoid;
                // Document doesn't exist, create it
                const userProfile = doc(collection(DB, col));

                await setDoc(userProfile, {
                  demoid: demoID,
                  url: downloadURL,
                });
              }

              console.log('Documents processed successfully');
            } catch (error) {
              console.error('Error querying documents:', error);
            }
          } else {
            console.error('demoid is undefined');
          }

          resolve(downloadURL);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }
    );
  });
}

export async function getLogo(demoid) {
  console.log(demoid, 'DEMO ID');
  const snapshot = await getDocs(collection(DB, 'businesslogo'), where('demoid', '==', demoid));
  const results = [];
  snapshot.forEach((docs) => {
    if (docs.data().demoid === demoid) {
      results.push(docs.data());
    }
  });
  console.log(results[0]);
  return results[0];
}

export async function deleteLogo(url) {
  const collectionRef = collection(DB, 'businesslogo');

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where('url', '==', url));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Delete each matching document
      await deleteDoc(doc(collectionRef, docSnapshot.id));
    });

    console.log('Documents deleted successfully');
  } catch (error) {
    console.error('Error deleting documents:', error);
  }
}

export async function getProfilePhoto(demoid) {
  const collectionRef = collection(DB, 'business');

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where('demoid', '==', demoid));
  const results = [];
  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      results.push(docSnapshot.data());
    });
  } catch (error) {
    console.error('Error:', error);
  }
  return results[0];
}

export async function deleteProfilePhoto(url) {
  const collectionRef = collection(DB, 'business');

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where('url', '==', url));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Update each matching document to remove the URL field
      await updateDoc(doc(collectionRef, docSnapshot.id), {
        url: null, // Set the URL field to null
        // or use deleteField to remove the URL field altogether:
        // url: deleteField()
      });
    });

    console.log('URLs removed successfully');
  } catch (error) {
    console.error('Error removing URLs:', error);
  }
}
