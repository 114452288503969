import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
// @mui
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router';
import { getSingle } from 'src/auth/context/firebase/auth-provider';

// ----------------------------------------------------------------------

DemoRouteView.propTypes = {
  complete: PropTypes.func,
};

export default function DemoRouteView({ complete }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const urlID = window.location.origin;
  useEffect(() => {
    getBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, urlID]);
  const getBusinessDetails = async () => {
    const businessDetails = await getSingle('business', 'customDomain', urlID);
    console.log(urlID, 'URL ID');
    if (businessDetails) {
      if (urlID.includes('localhost') || urlID.includes('demogrowth')) {
        setLoading(false);
      } else {
        navigate(`/demo/${businessDetails[0].demoid}`);
      }
    }
  };
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        minHeight: '100vh',
        textAlign: 'center',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          opacity: 0.24,
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      <Card
        sx={{
          py: 5,
          px: 5,
          width: 500,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Stack spacing={1} sx={{ my: 0 }}>
            <Typography variant="h3">Demo Growth</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Please check your URL
            </Typography>
            <Button onClick={() => navigate(`/dashboard`)}>Admin Login</Button>
          </Stack>
        )}
      </Card>
    </Box>
  );
}
