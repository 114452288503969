import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { fData } from 'src/utils/format-number';

// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';

// @mui
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// hooks
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { addDocument, getSingle, uploadMedia } from 'src/auth/context/firebase/auth-provider';
import uuidv4 from 'src/utils/uuidv4';
import { Grid } from '@mui/material';

// ----------------------------------------------------------------------

FormDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default function FormDialog({ onSubmit }) {
  const dialog = useBoolean();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: user?.name || '',
      email: user?.email || '',
      status: user?.status,
      company: user?.company || '',
      id: user?.id || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const [Question, setQuestion] = useState();

  const newId = uuidv4();

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const submitNewQuestion = async () => {
    const demoID = await getSingle('users', 'uid', user?.uid);
    const submit = await addDocument('question', user?.uid, {
      question: Question,
      demoid: demoID[0]?.demoid,
      date: new Date(),
    });
    if (submit) {
      handleSubmit();
      onSubmit();
      dialog.onFalse();
    }
  };

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider>
      <Button variant="outlined" color="success" onClick={dialog.onTrue}>
        New Question
      </Button>

      <Dialog open={dialog.value} onClose={dialog.onFalse}>
        <DialogTitle>New Question</DialogTitle>

        <DialogContent>
          <TextField
            style={{ width: '500px' }}
            autoFocus
            fullWidth
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            type="text"
            margin="dense"
            variant="outlined"
            label="Question"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
            Cancel
          </Button>
          <Button variant="contained" onClick={submitNewQuestion}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* </FormProvider> */}
    </FormProvider>
  );
}
