import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// assets
import { PasswordIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import PlanFreeIcon from 'src/assets/icons/plan-free-icon';
import { addDocument, getSingle } from 'src/auth/context/firebase/auth-provider';
import uuidv4 from 'src/utils/uuidv4';
import { useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

DemoWelcomeView.propTypes = {
  complete: PropTypes.func,
};

export default function DemoWelcomeView({ complete }) {
  const [loading, setLoading] = useState(true);
  const [businessName, setBusinessName] = useState('Demo');
  const urlID = window.location.pathname;
  const lastPart = urlID.split('/').pop();
  useEffect(() => {
    getBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlID]);
  const getBusinessDetails = async () => {
    const businessDetails = await getSingle('business', 'demoid', lastPart);
    if (businessDetails[0].businessName) {
      setBusinessName(businessDetails[0].businessName);
      document.title = `Demo | ${businessDetails[0].businessName}`;
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      phonenumber: '',
      orginisation: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control, // Add the control object from useForm
  } = methods;

  const newId = uuidv4();

  const navigate = useNavigate();

  const path = window.location.pathname;
  const currentId = path.split('/').pop();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const submit = await addDocument('leads', '', {
          firstName: data.firstname, // Access the form field values from the submitted data
          lastName: data.lastname,
          emailAddress: data.email,
          phoneNumber: data.phonenumber,
          orginisation: data.orginisation,
          demoid: currentId,
          leadid: newId,
          date: new Date(),
        });
        if (submit) {
          navigate(`/demo/${currentId}/${newId}`);
          // complete('questions');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [newId, navigate, currentId]
  );

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField control={control} name="firstname" label="First Name" />
      <RHFTextField control={control} name="lastname" label="Last Name" />
      <RHFTextField control={control} name="email" label="Email address" />
      <RHFTextField control={control} name="phonenumber" label="Phone Number" />
      <RHFTextField control={control} name="orginisation" label="Orginization Name" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        Get Started
      </LoadingButton>
    </Stack>
  );

  const renderHead = (
    <>
      <PlanFreeIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 0 }}>
        <Typography variant="h3">{businessName}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          To start, please enter some details
        </Typography>
      </Stack>
    </>
  );

  return (
    <Box
      component="main"
      sx={{
        // mt: 12,
        display: 'flex',
        minHeight: '100vh',
        textAlign: 'center',
        // px: { xs: 6, md: 0 },
        // position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          opacity: 0.24,
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      <Card
        sx={{
          py: 5,
          px: 3,
          width: 420,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}

            {renderForm}
          </FormProvider>
        )}
      </Card>
    </Box>
  );
}
