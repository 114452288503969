import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getMultiple,
  getMultipleDemoID,
  getSingle,
  getUniqueDemoID,
  getUniqueLeadId,
  updateSingle,
} from 'src/auth/context/firebase/auth-provider';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
  CircularProgress,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useIntercom } from 'react-use-intercom';

DemoTestimonials.propTypes = {
  complete: PropTypes.func,
};

export default function DemoTestimonials({ complete }) {
  const [isLoading, setIsLoading] = useState(false);
  const { boot, shutdown, hide, show, update } = useIntercom();
  const [selected, setSelected] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [businessName, setBusinessName] = useState();
  const [leadData, setLeadData] = useState();
  const urlID = window.location.pathname;
  const lastPart = urlID.split('/').pop();
  function getDemoId(url) {
    const regex = /\/demo\/([^/]+)/;
    const match = url.match(regex);

    if (match && match.length >= 2) {
      return match[1];
    }

    return null;
  }

  const demoId = getDemoId(urlID);

  const getQuestions = useCallback(async () => {
    const questions = await getMultipleDemoID('question', 'demoid', demoId);
    setAllQuestions(questions);
  }, [demoId]);

  const getBusinessDetails = async () => {
    const businessDetails = await getSingle('business', 'demoid', demoId);
    if (businessDetails[0].businessName) {
      setBusinessName(businessDetails[0]?.businessName);
    } else {
      setBusinessName('Demo Growth');
    }
  };

  useEffect(() => {
    boot();
    if (allQuestions.length < 1) getQuestions();
    if (!businessName) getBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestions, getQuestions, businessName]);

  const fetchDemoData = async () => {
    setIsLoading(true);
    const getLeadData = await getUniqueLeadId('leads', 'leadid', lastPart);
    if (getLeadData) {
      setLeadData(getLeadData[0]);
      if (getLeadData[0].answers) {
        complete('video');
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!leadData) fetchDemoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadData]);

  const handleCheckboxChange = (event, question) => {
    if (event.target.checked) {
      setSelected((prevSelected) => [...prevSelected, { question, option: event.target.value }]);
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem.question !== question)
      );
    }
  };

  const isOptionSelected = (question, option) => {
    selected.some(
      (selectedItem) => selectedItem.question === question && selectedItem.option === option
    );
    return selected.some(
      (selectedItem) => selectedItem.question === question && selectedItem.option === option
    );
  };

  const questionComplete = async () => {
    const selectedQuestions = selected.reduce((obj, selectedItem) => {
      obj[selectedItem.question] = selectedItem.option;
      return obj;
    }, {});
    const updateAnswers = await updateSingle('leads', 'leadid', lastPart, {
      answers: selectedQuestions,
    });
    console.log(updateAnswers);
    complete('video');
  };

  return (
    <Container component={MotionViewport} sx={{ textAlign: 'center', py: { xs: 10, md: 15 } }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          style={{ borderRadius: '30px' }}
          sx={{
            p: 10,
            position: 'relative',
            alignItems: 'center',
            textAlign: 'center',
            bgcolor: 'background.neutral',
            '&:before': {
              top: 0,
              left: 0,
              width: 1,
              content: "''",
              position: 'absolute',
              bgcolor: 'background.default',
            },
          }}
        >
          <p style={{ fontWeight: 'bold' }}>
            What is most priority to learn about {businessName} Platform?
          </p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="center">High Priority</TableCell>
                <TableCell align="center">Priority</TableCell>
                <TableCell align="center">Low Priority</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allQuestions.map((questionObj) => (
                <TableRow key={questionObj.question}>
                  <TableCell>{questionObj.question}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      onChange={(event) =>
                        handleCheckboxChange(event, questionObj.question, 'High')
                      }
                      checked={isOptionSelected(questionObj.question, 'High')}
                      disabled={
                        isOptionSelected(questionObj.question, 'Medium') ||
                        isOptionSelected(questionObj.question, 'Low')
                      }
                      value="High"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      onChange={(event) =>
                        handleCheckboxChange(event, questionObj.question, 'Medium')
                      }
                      checked={isOptionSelected(questionObj.question, 'Medium')}
                      disabled={
                        isOptionSelected(questionObj.question, 'High') ||
                        isOptionSelected(questionObj.question, 'Low')
                      }
                      value="Medium"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      onChange={(event) => handleCheckboxChange(event, questionObj.question, 'Low')}
                      checked={isOptionSelected(questionObj.question, 'Low')}
                      disabled={
                        isOptionSelected(questionObj.question, 'High') ||
                        isOptionSelected(questionObj.question, 'Medium')
                      }
                      value="Low"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button onClick={questionComplete} sx={{ mt: 10, fontSize: 20 }}>
            Next
          </Button>
        </Box>
      )}
    </Container>
  );
}
