import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useAuthContext } from 'src/auth/hooks';
// routes
import { RouterLink } from 'src/routes/components';
import MainLogo from 'src/assets/logo/demogrowth-logo.png';
import { getLogo, getSingle } from 'src/auth/context/firebase/auth-provider';
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const [businessLogo, setBusinessLogo] = useState();
  const { user } = useAuthContext();

  const saveDataToCookies = (logoData) => {
    const data = logoData;
    Cookies.set('logo', JSON.stringify(data));
  };

  const retrieveDataFromCookies = () => {
    const data = Cookies.get('logo');
    if (data) {
      const parsedData = JSON.parse(data);
      setBusinessLogo(parsedData.url);
    } else if (!data) {
      getBusinessLogo(); // Call the function to retrieve logo from Firebas
    }
  };

  useEffect(() => {
    retrieveDataFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessLogo]);

  const getBusinessLogo = async () => {
    const logo = await getLogo(user?.uid);
    if (logo) {
      setBusinessLogo(logo?.url);
      saveDataToCookies(logo);
    } else if (!logo) {
      setBusinessLogo(MainLogo);
    }
  };

  const [loading, setLoading] = useState(true);
  const [businessName, setBusinessName] = useState('');
  const urlID = window.location.pathname;
  const lastPart = urlID.split('/').pop();
  useEffect(() => {
    getBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlID]);
  function getDemoId(url) {
    const regex = /\/demo\/([^/]+)/;
    const match = url.match(regex);

    if (match && match.length >= 2) {
      return match[1];
    }

    return null;
  }

  const demoId = getDemoId(urlID);
  const getBusinessDetails = async () => {
    const businessDetails = await getSingle('business', 'demoid', demoId);
    if (businessDetails[0]?.businessName) {
      setBusinessName(businessDetails[0]?.businessName);
      document.title = `Demo | ${businessDetails[0]?.businessName}`;
      setLoading(false);
    } else {
      setBusinessName('Demo Growth');
      setLoading(false);
    }
  };

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    // <Box
    //   component="img"
    //   src={MainLogo}
    //   sx={{ maxWidth: 150, maxHeight: 150, cursor: 'pointer', ...sx }}
    // />
    <h1 style={{ marginLeft: '30px' }}>{businessName}</h1>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
