import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { fData } from 'src/utils/format-number';

// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';

// @mui
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// hooks
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { addDocument, uploadMedia } from 'src/auth/context/firebase/auth-provider';
import uuidv4 from 'src/utils/uuidv4';
import { Grid } from '@mui/material';

// ----------------------------------------------------------------------

FormDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default function FormDialog({ onSubmit }) {
  const dialog = useBoolean();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: user?.name || '',
      email: user?.email || '',
      status: user?.status,
      company: user?.company || '',
      id: user?.id || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const [fileName, setFileName] = useState();
  const [newName, setNewName] = useState();
  const [features, setFeature] = useState();
  const [video, setVideo] = useState();

  const newId = uuidv4();

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const submitNewClient = async () => {
    const submit = await addDocument('videos', user?.uid, {
      name: newName,
      feature: features,
      url: video,
      id: newId,
      uid: user?.uid,
      date: new Date(),
    });
    if (submit) {
      handleSubmit();
      dialog.onFalse();
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      setLoading(true);

      const file = acceptedFiles[0];
      setFileName(file.name);
      const downloadURL = await uploadMedia(user?.uid, 'demovideo', file);
      setVideo(downloadURL);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider>
      <Button variant="outlined" color="success" onClick={dialog.onTrue}>
        New Video
      </Button>

      <Dialog open={dialog.value} onClose={dialog.onFalse}>
        <DialogTitle>New Video</DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            Enter all the details to add this video to your demo
          </Typography>

          <TextField
            autoFocus
            fullWidth
            onChange={(e) => {
              setNewName(e.target.value);
            }}
            type="text"
            margin="dense"
            variant="outlined"
            label="Name"
          />

          <TextField
            fullWidth
            onChange={(e) => {
              setFeature(e.target.value);
            }}
            type="text"
            margin="dense"
            variant="outlined"
            label="Feature"
          />

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h6" sx={{ textAlign: 'left' }}>
              Video
            </Typography>
            {!loading ? (
              <RHFUploadAvatar
                name="photoURL"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Video <br />
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            ) : (
              <Typography
                variant="caption"
                sx={{
                  mt: 3,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.disabled',
                }}
              >
                {fileName}
              </Typography>
            )}
          </FormProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
            Cancel
          </Button>
          <Button variant="contained" onClick={submitNewClient}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* </FormProvider> */}
    </FormProvider>
  );
}
