// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // comingSoon: '/coming-soon',
  // maintenance: '/maintenance',
  // pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  // faqs: '/faqs',
  // page403: '/403',
  // page404: '/404',
  // page500: '/500',
  // components: '/components',
  // docs: 'https://docs.minimals.cc',
  // changelog: 'https://docs.minimals.cc/changelog',
  // zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  // minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  // figma:
  //   'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  // product: {
  //   root: `/product`,
  //   checkout: `/product/checkout`,
  //   details: (id) => `/product/${id}`,
  //   demo: {
  //     details: `/product/${MOCK_ID}`,
  //   },
  // },
  // post: {
  //   root: `/post`,
  //   details: (title) => `/post/${paramCase(title)}`,
  //   demo: {
  //     details: `/post/${paramCase(MOCK_TITLE)}`,
  //   },
  // },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/user/login`,
      verify: `${ROOTS.AUTH}/user/verify`,
      register: `${ROOTS.AUTH}/user/register`,
      forgotPassword: `${ROOTS.AUTH}/user/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  demo: {
    // root: `/demo`,
    root: (id) => `/demo/${id}/`,
    details: (id) => `/demo/${id}/${id}`,
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      health: {
        root: `${ROOTS.DASHBOARD}/health`,
        kpi: `${ROOTS.DASHBOARD}/health/kpi`,
        actions: `${ROOTS.DASHBOARD}/health/actions`,
      },
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      leads: `${ROOTS.DASHBOARD}/leads`,
      demos: `${ROOTS.DASHBOARD}/demos`,
      questions: `${ROOTS.DASHBOARD}/questions`,

      // booking: `${ROOTS.DASHBOARD}/booking`,
      // file: `${ROOTS.DASHBOARD}/file`,
    },
    planning: {
      marketing: `${ROOTS.DASHBOARD}/planning/marketing`,
      twitter: `${ROOTS.DASHBOARD}/planning/twitter`,
      sales: `${ROOTS.DASHBOARD}/planning/sales`,
    },
    user: {
      // root: `${ROOTS.DASHBOARD}/user`,
      // new: `${ROOTS.DASHBOARD}/user/new`,

      // cards: `${ROOTS.DASHBOARD}/user/cards`,
      // profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,

      // edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      // demo: {
      //   edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      // },
    },
    // product: {
    //   root: `${ROOTS.DASHBOARD}/product`,
    //   new: `${ROOTS.DASHBOARD}/product/new`,
    //   details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
    //     edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
    //   },
    // },
    // invoice: {
    //   root: `${ROOTS.DASHBOARD}/invoice`,
    //   new: `${ROOTS.DASHBOARD}/invoice/new`,
    //   details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
    //     edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
    //   },
    // },
    // post: {
    //   root: `${ROOTS.DASHBOARD}/post`,
    //   new: `${ROOTS.DASHBOARD}/post/new`,
    //   details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
    //   edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
    //     edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
    //   },
    // },
    // order: {
    //   root: `${ROOTS.DASHBOARD}/order`,
    //   details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
    //   },
    // },
    // integrations: {
    //   root: `${ROOTS.DASHBOARD}/integrations`,
    //   new: `${ROOTS.DASHBOARD}/integrations/new`,
    //   details: (id) => `${ROOTS.DASHBOARD}/integrations/${id}`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/integrations/${id}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/integrations/${MOCK_ID}`,
    //     edit: `${ROOTS.DASHBOARD}/integrations/${MOCK_ID}/edit`,
    //   },
    // },
    // tour: {
    //   root: `${ROOTS.DASHBOARD}/tour`,
    //   new: `${ROOTS.DASHBOARD}/tour/new`,
    //   details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
    //     edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
    //   },
    // },
  },
};
